/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('assets/fonts/source-sans-pro/source-sans-pro-v21-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('assets/fonts/source-sans-pro/source-sans-pro-v21-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('assets/fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('assets/fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('assets/fonts/source-sans-pro/source-sans-pro-v21-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('assets/fonts/source-sans-pro/source-sans-pro-v21-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: 'Source Sans Pro';
}

a {
  text-decoration: none;
  box-direction: none;
}

* {
  box-sizing: border-box;
 
}

*::-webkit-scrollbar {
  width: 8px;

  background: #F9F9F9;
}

*::-webkit-scrollbar-track {
  background: #F9F9F9;
  position: absolute;
}

*::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #B2B2B2;
  border-radius: 8px;
}

:root {
  --toastify--width: 480px;
}
*:focus {
  outline: none;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  width: var(--toastify--width);
}