.calendar .MuiPickersDateRangeDay-day span {
  color: #000000;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.calendar .MuiPickersDay-root.Mui-selected {
  background-color: #3047C7;
}
.calendar .MuiPickersDay-root.Mui-selected span {
  color: #ffffff;
}
.calendar .MuiPickersDateRangeDay-dayInsideRangeInterval {
  background-color: #CBD0F0;
}
.calendar .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
  background-color: #CBD0F0;
}
.calendar .MuiIconButton-label {
  color: #3047C7;
}
.calendar .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
  justify-content: center;
  gap: 16px;
}
.calendar .MuiPickersCalendar-daysHeader span {
  color: #000000;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}
.calendar .MuiTypography-subtitle1  {
  color: #000000;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}