

.Toastify__toast {
    cursor: pointer;
    direction: ltr;
    z-index: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
     z-index: 999;
     width: var(--toastify--width);
}
